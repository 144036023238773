/* eslint-disable no-unused-vars */
/* eslint-disable no-shadow */
export enum FinancialFormFieldsEnum {
  EMAIL="checkInStep.email",
  AUTH_DATA_TREATMENT="checkInStep.authDataTreatment",
  // userInfoStep
  NAME = "userInfoStep.name",
  FIRST_LAST_NAME = "userInfoStep.firstLastName",
  SECOND_LAST_NAME = "userInfoStep.secondLastName",
  PHONE = "userInfoStep.phone",
  ADDRESS = "userInfoStep.address",
  AGE = "userInfoStep.age",
  CURP = "userInfoStep.curp",
  GENDER = "userAddressStep.gender",
  DAY = "userAddressStep.day",
  MONTH = "userAddressStep.month",
  YEAR = "userAddressStep.year",

  CP = "userAddressStep.fullAddress.cp",
  STATE = "userAddressStep.fullAddress.state",
  DELEGATION = "userAddressStep.fullAddress.delegation",
  NEIGHBORHOOD = "userAddressStep.fullAddress.neighborhood",
  STREET = "userAddressStep.fullAddress.street",
  EXT_NUMBER = "userAddressStep.fullAddress.extNumber",
  INT_NUMBER = "userAddressStep.fullAddress.intNumber",
  LAT = "userAddressStep.fullAddress.lat",
  LONG = "userAddressStep.fullAddress.long",

  // incomeStep
  MONTHLY_INCOME = "incomeStep.monthlyIncome",
  MONTHLY_OUTCOME = "incomeStep.monthlySpending",
  // dependantsStep
  CHILDREN_COUNT = "dependantsStep.childrenCount",
  DEPENDANTS_COUNT = "dependantsStep.dependantsCount",
  ECONOMIC_ACTIVITY_VALUE = "dependantsStep1.economicActivity.value",
  ECONOMIC_ACTIVITY_DESCRIPTION = "dependantsStep1.economicActivity.description",
  COMPANY_NAME = "dependantsStep1.companyName",
  EDUCATIONAL_LEVEL = "dependantsStep1.educationalLevel",
  // userStatusStep
  CIVIL_STATUS = "userStatusStep.civilStatus",
  LIVES_WITH = "userStatusStep.livesWith",
  ASSETS = "userStatusStep1.assets",
  OTHER_ASSETS = "userStatusStep1.otherAsset",
}

export enum OzonerActivityOptions {
  EMPLOYEE = "empleado",
  EMPLOYEE_LABEL = "Empleado",
  DELIVERY = "repartidor",
  DELIVERY_LABEL = "Repartidor",
  BUSINESSMAN = "comerciante",
  BUSINESSMAN_LABEL = "Comerciante",
  DRIVER = "conductor",
  DRIVER_LABEL = "Conductor",
  DELIVERY_DRIVER = "conductorRepartidor",
  DELIVERY_DRIVER_LABEL = "Conductor repartidor",
  ASSISTANT_GENERAL = "ayudanteGeneral",
  ASSISTANT_GENERAL_LABEL = "Ayudante general",
  SECURITY = "seguridad",
  SECURITY_LABEL = "Seguridad",
  SELF_EMPLOYED = "independiente",
  SELF_EMPLOYED_LABEL = "Independiente",
  STUDENT = "estudiante",
  STUDENT_LABEL = "Estudiante",
  POLICE = "policia",
  POLICE_LABEL = "Policia",
  PUBLIC_SERVER = "servidorPublico",
  PUBLIC_SERVER_LABEL = "Servidor público",
  NURSE = "enfermero",
  NURSE_LABEL = "Enfermero",
  MECHANIC = "mecanico",
  MECHANIC_LABEL = "Mecánico",
  OTHER = "otro",
  OTHER_LABEL = "Otro"
}
